<template>
  <div id="service-fee-tab">
    <IAmOverlay :loading="isLoading">
      <AppCollapse
        v-if="feeServicesData.length"
        type="card"
      >
        <AppCollapseItem
          v-for="(feeServiceProfileItem) in feeServicesData"
          :key="feeServiceProfileItem.id"
          title
          :is-visible="feeServiceProfileItem.isDefault"
          class-header="card-header bg-light-info rounded"
          class="mb-50"
        >
          <template #header>
            <div class="d-flex">
              <h6 class="card-title font-weight-bolder">
                {{ feeServiceProfileItem.name.toUpperCase() }}
              </h6>
              <BBadge
                v-if="feeServiceProfileItem.isDefault"
                variant="success"
                class="badge-glow round pills ml-50"
              >
                {{ $t('fee.default') }}
              </BBadge>
            </div>
          </template>
          <validation-observer>
            <b-card-body class="py-1 px-2">
              <b-table
                :fields="tableColumnsForList"
                striped
                bordered
                hover
                responsive
                :empty-text="$t('noMatchingResult')"
                :items="feeServiceProfileItem.serviceFeeConfigs.filter(item => item.ticketType === ticketType)"
              >
                <!-- ANCHOR Header -->
                <template
                  v-for="(column) in tableColumnsForList"
                  #[`head(${column.key})`]="data"
                >
                  <div
                    :key="column.label"
                    class="text-dark text-nowrap"
                    :class="{
                      'text-right': ['adultAmount', 'childAmount', 'infantAmount'].includes(data.label),
                      'text-center': ['action'].includes(data.label)
                    }"
                  >
                    {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
                  </div>
                </template>
                <template #cell(airline)="data">
                  <div class="d-flex-center gap-1 font-weight-bolder">
                    <div
                      style="width: 50px"
                      class="d-flex-center"
                    >
                      <IAmLogoAirline
                        :airline="data.item.airline.toUpperCase() "
                        :height="30"
                      />
                    </div>
                    <span class="flex-1">
                      {{ $te(`flight.airlines.${data.item.airline.toUpperCase()}`)
                        ? $t(`flight.airlines.${data.item.airline.toUpperCase()}`)
                        : '' }}
                      ({{ $te(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                        ? $t(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                        : data.item.airline.toUpperCase() }})
                    </span>
                  </div>
                </template>
                <template #cell(feeType)="data">
                  <b-form-group
                    v-if="editFeeServiceIndex === data.index && editFeeServiceId === data.item.id"
                    label-for="feeTypeEdit"
                    class="custom-form-group"
                    style="min-width: 300px;"
                  >
                    <v-select
                      v-model="data.item.feeType"
                      :append-to-body="true"
                      :calculate-position="withPopper"
                      :options="ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                      :reduce="(val) => val.value"
                      :disabled="['TRAIN'].includes(data.item.airline)"
                      :clearable="false"
                      :placeholder="$t('fee.placeholder.feeType')"
                    >
                      <template #option="{ value }">
                        <span class="d-one-line">
                          {{ $t(`fee.${value}`) }}
                        </span>
                      </template>

                      <template #selected-option="{ value }">
                        <span class="d-one-line">
                          {{ $t(`fee.${value}`) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-form-group>
                  <div v-else>
                    {{ $t(`fee.${data.item.feeType}`) }}
                  </div>
                </template>
                <template #cell(adultAmount)="data">
                  <validation-provider
                    v-if="editFeeServiceIndex === data.index && editFeeServiceId === data.item.id"
                    #default="validationContext"
                    :name="$t('fee.amount')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="amount-adult"
                      class="custom-form-group"
                      style="min-width: 95px;"
                    >
                      <IAmInputMoney
                        id="amount-adult"
                        :value-money.sync="feeServiceDataToEdit.adultAmount"
                        class="flex-grow-1"
                        customClass="fw-700"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('fee.placeholder.amount')"
                        @input="val => handleInputAdultAmount('toEdit', val)"
                      />
                    </b-form-group>
                    <div
                      v-if="showProfit"
                      class="d-flex-center justify-content-end mt-50"
                    >
                      <small class="font-italic">
                        Lợi nhuận dự kiến:
                        <span :class="`fw-500 ${feeServiceDataToEdit.adultAmount <= getAgencyFee(data.item.ticketType, data.item.airline) ? 'text-danger': ''}`">
                          {{ formatCurrency(
                            feeServiceDataToEdit.adultAmount - getAgencyFee(data.item.ticketType, data.item.airline)
                          ) }}
                        </span>
                      </small>
                    </div>
                  </validation-provider>
                  <div
                    v-else
                    class="text-right"
                  >
                    <span class="fw-700">{{ formatCurrency(data.item.adultAmount) }}</span>
                    <div
                      v-if="showProfit"
                      class="d-flex-center justify-content-end mt-50"
                    >
                      <small class="font-italic">
                        Lợi nhuận dự kiến:
                        <span :class="`fw-500 ${data.item.adultAmount <= getAgencyFee(data.item.ticketType, data.item.airline) ? 'text-danger': ''}`">
                          {{ formatCurrency(
                            (data.item.adultAmount) - getAgencyFee(data.item.ticketType, data.item.airline)
                          ) }}
                        </span>
                      </small>
                    </div>
                  </div>
                </template>
                <template #cell(childAmount)="data">
                  <validation-provider
                    v-if="editFeeServiceIndex === data.index && editFeeServiceId === data.item.id"
                    #default="validationContext"
                    :name="$t('fee.amount')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="amount-child"
                      class="custom-form-group"
                      style="min-width: 95px;"
                    >
                      <IAmInputMoney
                        id="amount-child"
                        :value-money.sync="feeServiceDataToEdit.childAmount"
                        class="flex-grow-1"
                        customClass="fw-700"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('fee.placeholder.amount')"
                      />
                    </b-form-group>
                    <div
                      v-if="showProfit"
                      class="d-flex-center justify-content-end mt-50"
                    >
                      <small class="font-italic">
                        Lợi nhuận dự kiến:
                        <span :class="`fw-500 ${feeServiceDataToEdit.childAmount <= getAgencyFee(data.item.ticketType, data.item.airline) ? 'text-danger': ''}`">
                          {{ formatCurrency(
                            feeServiceDataToEdit.childAmount - getAgencyFee(data.item.ticketType, data.item.airline)
                          ) }}
                        </span>
                      </small>
                    </div>
                  </validation-provider>
                  <div
                    v-else
                    class="text-right"
                  >
                    <span class="fw-700">{{ formatCurrency(data.item.childAmount) }}</span>
                    <div
                      v-if="showProfit"
                      class="d-flex-center justify-content-end mt-50"
                    >
                      <small class="font-italic">
                        Lợi nhuận dự kiến:
                        <span :class="`fw-500 ${data.item.childAmount <= getAgencyFee(data.item.ticketType, data.item.airline) ? 'text-danger': ''}`">
                          {{ formatCurrency(
                            (data.item.childAmount) - getAgencyFee(data.item.ticketType, data.item.airline)
                          ) }}
                        </span>
                      </small>
                    </div>
                  </div>
                </template>
                <template #cell(infantAmount)="data">
                  <validation-provider
                    v-if="editFeeServiceIndex === data.index && editFeeServiceId === data.item.id"
                    #default="validationContext"
                    :name="$t('fee.amount')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="amount-infant"
                      class="custom-form-group"
                      style="min-width: 95px;"
                    >
                      <IAmInputMoney
                        id="amount-infant"
                        :value-money.sync="feeServiceDataToEdit.infantAmount"
                        class="flex-grow-1"
                        :disabled="['TRAIN'].includes(data.item.airline)"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :placeholder="$t('fee.placeholder.amount')"
                      />
                    </b-form-group>
                  </validation-provider>

                  <div
                    v-else
                    class="text-right"
                  >
                    {{ formatCurrency(data.item.infantAmount) }}
                  </div>
                </template>

                <template #cell(action)="data">
                  <div class="text-center">
                    <div
                      v-if="editFeeServiceIndex === data.index && editFeeServiceId === data.item.id"
                      class="d-flex-center flex-nowrap"
                    >
                      <b-button
                        class="text-center px-50"
                        variant="flat-success"
                        :disabled="isLoading"
                        @click="updateFeeService(data.item)"
                      >
                        <IAmOverlay :loading="isLoading">
                          <feather-icon
                            icon="CheckIcon"
                            size="16"
                            style="cursor: pointer"
                          />
                        </IAmOverlay>
                      </b-button>
                      <b-button
                        class="text-center px-50"
                        variant="flat-danger"
                        :disabled="isLoading"
                        @click="() => handleTriggerEditFeeService(data, false)"
                      >
                        <IAmOverlay :loading="isLoading">
                          <feather-icon
                            icon="XIcon"
                            size="16"
                            style="cursor: pointer"
                          />
                        </IAmOverlay>
                      </b-button>
                    </div>
                    <b-button
                      v-else
                      variant="flat-info"
                      @click="() => handleTriggerEditFeeService(data, true)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        style="cursor: pointer"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>

              <div
                v-if="!feeServiceProfileItem.isDefault"
                class="d-flex-center justify-content-end gap-3"
              >
                <b-button
                  variant="outline-info"
                  class="d-flex-center gap-1 cursor-pointer"
                  @click="handleActiveDefaultEmployeeProfile(feeServiceProfileItem)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                  />
                  {{ $t('fee.setAsDefault') }}
                </b-button>
                <b-button
                  variant="danger"
                  class="d-flex-center gap-1 cursor-pointer"
                  @click="handleDeleteEmployeeProfile(feeServiceProfileItem.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                  />
                  {{ $t('fee.deleteProfile') }}
                </b-button>
              </div>
            </b-card-body>
          </validation-observer>
        </AppCollapseItem>
      </AppCollapse>
      <BAlert
        v-else
        show
        variant="warning"
        class="p-1"
      >
        <div>Không có cấu hình phí dịch vụ</div>
        <div>Vui lòng tạo cấu hình phí dịch vụ mới</div>
      </BAlert>
    </IAmOverlay>
  </div>
</template>

<script>
import {
  BCardBody, BFormGroup, BButton, BTable, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  watch, ref, computed, toRefs,
} from '@vue/composition-api'
import { createPopper } from '@popperjs/core'

import { distributorOptions, feeTypeOptions } from '@/constants/selectOptions'
import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'

import useServiceFeeHandle from './useServiceFeeHandle'

export default {
  components: {
    BCardBody,
    BBadge,
    BFormGroup,
    BButton,
    BTable,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    BAlert,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    ticketType: {
      type: String,
      default: 'INLAND',
    },
    feeServicesDataProps: {
      type: Array,
      default: () => [],
    },
    employeeId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.border = '1px solid #b8c2cc'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()

    const {
      tableColumns, deleteEmployeeProfile, activeDefaultEmployeeProfile, createServiceFeeMany,
    } = useServiceFeeHandle()

    const { getValidationState, refFormObserver } = formValidation()

    const tableColumnsForList = tableColumns.value.filter(item => !['applyAll'].includes(item.key))

    const feeServiceDataToAdd = ref({
      airline: undefined,
      adultAmount: 0,
      childAmount: 0,
      infantAmount: 0,
    })
    const feeType = ref('FLIGHT_ITINERARY')
    const feeServiceDataToEdit = ref({
      airline: undefined,
      adultAmount: 0,
      childAmount: 0,
      infantAmount: 0,
    })
    const isLoading = ref(false)
    const editFeeServiceIndex = ref()
    const editFeeServiceId = ref()
    const ticketType = ref(props.ticketType)
    const feeServicesData = toRefs(props).feeServicesDataProps

    watch(() => feeServiceDataToAdd.value.airline, () => {
      const airline = feeServiceDataToAdd.value.airline
      const feeServiceOfAirline = feeServicesData.value.find(item => item.airline === airline)
      if (feeServiceOfAirline) {
        feeType.value = feeServiceOfAirline.feeType
        feeServiceDataToAdd.value.adultAmount = feeServiceOfAirline.adultAmount
        feeServiceDataToAdd.value.childAmount = feeServiceOfAirline.childAmount
        feeServiceDataToAdd.value.infantAmount = feeServiceOfAirline.infantAmount
      } else {
        feeServiceDataToAdd.value.adultAmount = 0
        feeServiceDataToAdd.value.childAmount = 0
        feeServiceDataToAdd.value.infantAmount = 0
      }
    }, { deep: true })

    const isDisableBtn = computed(() => !feeType.value || !feeServiceDataToAdd.value.airline || !ticketType.value)

    async function handleTriggerEditFeeService(data, isEdit = true) {
      if (isEdit) {
        editFeeServiceIndex.value = data.index
        editFeeServiceId.value = data.item.id
        feeServiceDataToEdit.value.adultAmount = data.item.adultAmount
        feeServiceDataToEdit.value.childAmount = data.item.childAmount
        feeServiceDataToEdit.value.infantAmount = data.item.infantAmount
        feeServiceDataToEdit.value.airline = data.item.airline
        feeServiceDataToEdit.value.feeType = data.item.feeType
      } else {
        editFeeServiceIndex.value = undefined
        editFeeServiceId.value = undefined
      }
    }

    async function updateFeeService(feeServiceCurrent) {
      isLoading.value = true
      try {
        const payload = {
          items: [{
            airline: feeServiceDataToEdit.value.airline,
            feeType: feeServiceCurrent.feeType,
            adultAmount: feeServiceDataToEdit.value.adultAmount || 0,
            childAmount: feeServiceDataToEdit.value.childAmount || 0,
            infantAmount: feeServiceDataToEdit.value.infantAmount || 0,
            ticketType: feeServiceCurrent.ticketType,
            employeeProfileId: feeServiceCurrent.employeeProfile.id,
          }],
        }
        await createServiceFeeMany(props.employeeId, payload)
        toastSuccess({
          title: 'messagesList.success',
          content: 'fee.updateFeeConfigSuccess',
        })
        emit('getFeeConfigsData')
        editFeeServiceIndex.value = undefined
      } catch (error) {
        toastError({
          title: 'messagesList.error',
          content: 'fee.updateFeeConfigError',
        })
        console.error({ error })
      } finally {
        isLoading.value = false
      }
    }

    let tempAirlineToAdd = null
    let tempAirlineToEdit = null
    function handleInputAdultAmount(type, val) {
      if (type === 'toAdd') {
        if (feeServiceDataToAdd.value.airline === tempAirlineToAdd) {
          feeServiceDataToAdd.value.childAmount = val
        } else {
          tempAirlineToAdd = feeServiceDataToAdd.value.airline
        }
      }
      if (type === 'toEdit') {
        if (feeServiceDataToEdit.value.airline === tempAirlineToEdit) {
          feeServiceDataToEdit.value.childAmount = val
        } else {
          tempAirlineToEdit = feeServiceDataToEdit.value.airline
        }
      }
    }
    async function handleDeleteEmployeeProfile(employeeProfileId) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmDelete'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteEmployeeProfile(props.employeeId, employeeProfileId).then(() => emit('getFeeConfigsData'))
          }
        })
    }

    async function handleActiveDefaultEmployeeProfile(employeeProfile) {
      const res = await activeDefaultEmployeeProfile(props.employeeId, employeeProfile)
      if (res) {
        emit('getFeeConfigsData')
      }
    }

    function getAgencyFee(ticketType, airline) {
      const agencyFee = store.getters['userStore/getAgencyFee'](ticketType === 'INLAND', airline)
      return agencyFee?.amount || 0
    }

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isHideFee = computed(() => store.getters['userStore/getHideFee'])
    const showProfit = computed(() => !isRoleF1.value && !isHideFee.value)
    return {
      updateFeeService,
      feeServiceDataToAdd,
      refFormObserver,
      getValidationState,
      distributorOptions,
      formatCurrency,
      feeTypeOptions,
      feeServicesData,
      feeServiceDataToEdit,
      isLoading,
      editFeeServiceIndex,
      isDisableBtn,
      handleTriggerEditFeeService,
      tableColumnsForList,
      // convertISODateTime,
      handleInputAdultAmount,
      handleDeleteEmployeeProfile,
      handleActiveDefaultEmployeeProfile,
      editFeeServiceId,
      getAgencyFee,
      showProfit,
    }
  },
}
</script>
<style lang="scss">
.service-fee-tab-custom-tooltip > .tooltip-inner {
  max-width: 800px;
  text-align: justify;
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
.d-one-line {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.custom-form-group {
  margin-bottom: unset;
}
</style>
